import React from "react"

import styled from 'styled-components';
import theme from "../../../styles/theme";

import kolko from "../../../images/kolko.png";

import { FaBed, FaHeartbeat, FaDesktop } from 'react-icons/fa';

const InformationWrapper = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 5% auto 0px auto;
  font-size: 0.8rem;
  justify-content: center;

  @media(min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }

  svg {
    display: block;
    font-size: 2rem;
    margin: 40px auto;
    color: ${theme.colors.purple};
  }    
`;

const LeftSection = styled.div`
    text-align: center;
    width: 100%;

    @media(min-width: 992px) {
        width: 50%;
    }
`;

const RightSection = styled.div`
    text-align: center;
    width: 100%;

    @media(min-width: 992px) {
        width: 50%;
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    background-color: ${theme.colors.header};
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(min-width: 1200px) {
        padding: 50px;
    }

    svg {
        display: block;
        font-size: 2rem;
        margin: 0px auto 40px auto;
        color: ${theme.colors.purple};
    }  
      
    p {
        width: 100%;

        @media(min-width: 1200px) {
            width: 50%;
        }

        text-align: center;
        margin-bottom: 75px;
        font-size: 0.8rem;
    }

    margin: 5% auto 0 auto;
`;

const ImageContainer = styled.div`
    width: 80%;

    @media(min-width: 1200px) {
        width: 50%;
    }

    img {
        width: 100%;
        height: auto;
        margin: 0;
    }
`;

const SectionWrapper = styled.div``;

const Information = () => (
    <SectionWrapper>
        <InformationWrapper>
            <LeftSection>
                <FaHeartbeat />
                Infofinder wspiera polskich przedstawicieli ochrony zdrowia w zakresie wdrażania standardów zarządzania procesami logistyki szpitalnej z wykorzystaniem najnowocześniejszych narzędzi i standardów takich jak GS1/ADC/EDI/HL7
            </LeftSection>
            <RightSection>
                <FaDesktop />
                Farmmed (Zintegrowany system zarządzania lekiem i artykułem medycznym w szpitalu) to cyber-fizyczny system, który realizuje i usprawnia wszystkie istotne procesy logistyczne, opierając się na zasadzie zamkniętej pętli zarządzania informacją o leku.
            </RightSection>
        </InformationWrapper>
        <ImageWrapper>
            <FaBed />
            <p>Celem HLS w szpitalu jest koordynacja realizacji wszystkich działań,
które wspierają podstawowy proces w szpitalu – proces leczenia,
stosując realizację tzw. <strong>siedmiu praw pacjenta:</strong></p>
            <ImageContainer>
                <img src={kolko} alt="7 praw pacjenta"/>
            </ImageContainer>
        </ImageWrapper>
    </SectionWrapper>
)

export default Information
