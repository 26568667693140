import React from "react"

import styled from 'styled-components';

import { FaRegEnvelopeOpen } from 'react-icons/fa';

import theme from "../../../styles/theme";

const ContactWrapper = styled.div`
    text-align: center;
    margin: 50px 0px 100px 0px;
    
    svg {
        color: ${theme.colors.purple};
        font-size: 1.5em;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    P {
        font-size: 1.2rem;
    }

    a {
        border: 2px solid ${theme.colors.purple};
        display: block;
        width: fit-content;
        margin: 0 auto;
        padding: 13px 22px;
        text-decoration: none;
        font-weight: bold;
        font-size: 0.8em;
        margin-top: 30px;
        color: ${theme.colors.black};

        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

        :hover {
            background-color: ${theme.colors.purple};
            color: white;
            cursor: pointer;
        }
    }
`;

const Contact = () => (
    <ContactWrapper>
        <FaRegEnvelopeOpen />
        <p>SKONTAKTUJ SIĘ Z NAMI</p>
        <a href="/kontakt">ZAPYTANIE OFERTOWE</a>
    </ContactWrapper>
)

export default Contact

