import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/MainPage/Hero"
import Information from "../components/MainPage/Sections/Information";
import Contact from "../components/MainPage/Sections/Contact";
import Offer from "../components/MainPage/Sections/Offer";
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Information />
    <Contact />
    <Offer />
  </Layout>
)

export default IndexPage
