import React from "react"

import styled from 'styled-components';

import { FaPencilAlt } from 'react-icons/fa';

import theme from "../../../styles/theme";

const OfferWrapper = styled.div`
    text-align: center;
    margin: 50px 0px 50px 0px;
    padding-top: 50px;

    border-top: 1px solid ${theme.colors.lightGray};

    svg {
        color: ${theme.colors.purple};
        font-size: 1.5em;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    P {
        font-size: 0.8rem;
    }

    a {
        border: 2px solid ${theme.colors.purple};
        display: block;
        width: fit-content;
        margin: 0 auto;
        padding: 13px 22px;
        font-weight: bold;
        font-size: 0.8em;
        margin-top: 50px;

        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        color: black;
        text-decoration: none;
        
        :hover {
            background-color: ${theme.colors.purple};
            color: white;
            cursor: pointer;
        }
    }
`;

const Offer = () => (
    <OfferWrapper>
        <FaPencilAlt />
        <p>HLS – Zintegrowany System Zarządzania Lekami i Artykułami Medycznymi to efektywne wdrożenie rozwiązań logistycznych w szpitalach, z   wykorzystaniem technologii elektronicznej gospodarki, które przyczyniają się do usprawnienia zarządzania przepływem leków, materiałów medycznych i pacjentów oraz umożliwią rejestrację materiałowych kosztów procedur medycznych, obniżając jednocześnie zapasy o ok. 15%.</p>
        <a href="/oferta">OFERTA</a>
    </OfferWrapper>
)

export default Offer

