import React from "react"

import styled from 'styled-components';

import Slider from "react-slick";

import FirstPhoto from "../../images/slider.png"
import SecondPhoto from "../../images/slider2.png"
import ThirdPhoto from "../../images/slider3.png"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroWrapper = styled.div`
  width: 100%;
  padding: 10px;
  margin: 0 auto;
`;

const StyledLeft = styled.button`
  &::before {
    content: '◀';
    color: black;
  }
`;

const StyledRight = styled.button`
  &::before {
    content: '▶';
    color: black;
  }
`;

const ArrowLeft = (props) => (
  <StyledLeft
      {...props} />
);

const ArrowRight = (props) => (
  <StyledRight
      {...props} />
);

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
};

const Hero = () => (
  <HeroWrapper>
    <Slider {...settings}>
        <div>
          <img src={FirstPhoto} alt="Slider 1"/>
        </div>
        <div>
          <img src={SecondPhoto} alt="Slider 2"/>
        </div>
        <div>
          <img src={ThirdPhoto} alt="Slider 3"/>
        </div>
    </Slider>      
  </HeroWrapper>
)

export default Hero
